import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import useInput from "../../../hooks/useInput";
import axiosInstance from "../../../utils/AxiosHelper";
import "../../../Auth.css";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Loader from "../../../components/loader";

function AddUser({ open1, setOpen1 }) {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const userInfo = JSON.parse(localStorage.getItem("user"));

	const [isloading, setisLoading] = useState(false);
	const [otp, setOtp] = useState(null);

	// First Name
	let {
		enteredInput: enteredName,
		inputValid: nameInputIsValid,
		inputInvalid: nameInputIsInvalid,
		blurHandler: nameInputBlurHandler,
		changeHandler: nameInputChangeHandler,
		reset: nameInputReset,
	} = useInput("", (value) => value !== "");

	// MiddleName
	let {
		enteredInput: enteredMiddleName,
		inputValid: middleNameInputIsValid,
		inputInvalid: middleNameInputIsInvalid,
		blurHandler: middleNameInputBlurHandler,
		changeHandler: middleNameInputChangeHandler,
		reset: middleNameInputReset,
	} = useInput("", (value) => value !== "");

	// Last Name
	let {
		enteredInput: enteredlastName,
		inputValid: lastNameInputIsValid,
		inputInvalid: lastNameInputIsInvalid,
		blurHandler: lastNameInputBlurHandler,
		changeHandler: lastNameInputChangeHandler,
		reset: lastNameInputReset,
	} = useInput("", (value) => value !== "");

	// Amount
	let {
		enteredInput: enteredAmount,
		inputValid: amountInputIsValid,
		inputInvalid: amountInputIsInvalid,
		blurHandler: amountInputBlurHandler,
		changeHandler: amountInputChangeHandler,
		reset: amountInputReset,
	} = useInput("", (value) => value !== "");

	// Pan Card
	let {
		enteredInput: enteredPanCard,
		inputValid: panCardInputIsValid,
		inputInvalid: panCardInputIsInvalid,
		blurHandler: panCardInputBlurHandler,
		changeHandler: panCardInputChangeHandler,
		reset: panCardInputReset,
	} = useInput("", (value) => value !== "");

	// Mobile Number
	let {
		enteredInput: enteredmobileNumber,
		inputValid: mobileNumberInputIsValid,
		inputInvalid: mobileNumberInputIsInvalid,
		blurHandler: mobileNumberInputBlurHandler,
		changeHandler: mobileNumberInputChangeHandler,
		reset: mobileNumberInputReset,
	} = useInput("", (value) => value !== "" && value.length == 10);

	// Email

	let {
		enteredInput: enteredEmail,
		inputValid: emailInputIsValid,
		inputInvalid: emailInputIsInvalid,
		blurHandler: emailInputBlurHandler,
		changeHandler: emailInputChangeHandler,
		reset: emailInputReset,
	} = useInput("", (value) => value !== "");

	//Address 1

	let {
		enteredInput: enteredAddress1,
		inputValid: address1InputIsValid,
		inputInvalid: address1InputIsInvalid,
		blurHandler: address1InputBlurHandler,
		changeHandler: address1InputChangeHandler,
		reset: address1InputReset,
	} = useInput("", (value) => value !== "");

	//Address 2

	let {
		enteredInput: enteredAddress2,
		inputValid: address2InputIsValid,
		inputInvalid: address2InputIsInvalid,
		blurHandler: address2InputBlurHandler,
		changeHandler: address2InputChangeHandler,
		reset: address2InputReset,
	} = useInput("", (value) => value !== "");

	//Address 3

	let {
		enteredInput: enteredAddress3,
		inputValid: address3InputIsValid,
		inputInvalid: address3InputIsInvalid,
		blurHandler: address3InputBlurHandler,
		changeHandler: address3InputChangeHandler,
		reset: address3InputReset,
	} = useInput("", (value) => value !== "");

	//pincode

	let {
		enteredInput: enteredPincode,
		inputValid: pincodeInputIsValid,
		inputInvalid: pincodeInputIsInvalid,
		blurHandler: pincodeInputBlurHandler,
		changeHandler: pincodeInputChangeHandler,
		reset: pincodeInputReset,
	} = useInput("", (value) => value !== "");

	//city

	let {
		enteredInput: enteredCity,
		inputValid: cityInputIsValid,
		inputInvalid: cityInputIsInvalid,
		blurHandler: cityInputBlurHandler,
		changeHandler: cityInputChangeHandler,
		reset: cityInputReset,
	} = useInput("", (value) => value !== "");

	//State

	let {
		enteredInput: enteredState,
		inputValid: stateInputIsValid,
		inputInvalid: stateInputIsInvalid,
		blurHandler: stateInputBlurHandler,
		changeHandler: stateInputChangeHandler,
		reset: stateInputReset,
	} = useInput("", (value) => value !== "");

	// Donation Type

	let {
		enteredInput: enteredDonationType,
		inputValid: donationTypeInputIsValid,
		inputInvalid: donationTypeInputIsInvalid,
		blurHandler: donationTypeInputBlurHandler,
		changeHandler: donationTypeInputChangeHandler,
		reset: donationTypeInputReset,
	} = useInput(1, (value) => value !== "");

	// Payment Mode

	let {
		enteredInput: enteredPaymentMode,
		inputValid: paymentModeInputIsValid,
		inputInvalid: paymentModeInputIsInvalid,
		blurHandler: paymentModeInputBlurHandler,
		changeHandler: paymentModeInputChangeHandler,
		reset: paymentModeInputReset,
	} = useInput(1, (value) => value !== "");

	// Cheque

	let {
		enteredInput: enteredCheque,
		inputValid: chequeInputIsValid,
		inputInvalid: chequeInputIsInvalid,
		blurHandler: chequeInputBlurHandler,
		changeHandler: chequeInputChangeHandler,
		reset: chequeInputReset,
	} = useInput("", (value) => value !== "");

	// Bank Details

	let {
		enteredInput: enteredBankDetails,
		inputValid: bankDetailsInputIsValid,
		inputInvalid: bankDetailsInputIsInvalid,
		blurHandler: bankDetailsInputBlurHandler,
		changeHandler: bankDetailsInputChangeHandler,
		reset: bankDetailsInputReset,
	} = useInput("", (value) => value !== "");

	// Transaction Details

	let {
		enteredInput: enteredTransactionDetails,
		inputValid: transactionDetailsInputIsValid,
		inputInvalid: transactionDetailsInputIsInvalid,
		blurHandler: transactionDetailsInputBlurHandler,
		changeHandler: transactionDetailsInputChangeHandler,
		reset: transactionDetailsInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredDonationPurpose,
		inputValid: donationPurposeInputIsValid,
		inputInvalid: donationPurposeInputIsInvalid,
		blurHandler: donationPurposeInputBlurHandler,
		changeHandler: donationPurposeInputChangeHandler,
		reset: donationPurposeInputReset,
	} = useInput("", (value) => value !== "");

	const signUpHandler = async (e) => {
		e.preventDefault();

		nameInputBlurHandler();
		// emailInputBlurHandler();
		lastNameInputBlurHandler();
		panCardInputBlurHandler();
		amountInputBlurHandler();
		mobileNumberInputBlurHandler();
		address1InputBlurHandler();
		address2InputBlurHandler();
		pincodeInputBlurHandler();
		cityInputBlurHandler();
		stateInputBlurHandler();
		// donationTypeInputBlurHandler();

		if (
			(!enteredName &&
				!enteredlastName &&
				!enteredAmount &&
				!enteredAddress1 &&
				!enteredAddress2 &&
				!enteredPincode &&
				!enteredCity &&
				!enteredState &&
				!enteredmobileNumber &&
				!enteredPanCard) ||
			!(enteredmobileNumber.length == 10)
		) {
			return;
		}
		setisLoading(true);
		const response = await axiosInstance.post(
			"donation/create-donation-receipt",
			{
				donation_amount: enteredAmount,
				donor_first_name: enteredName,
				donor_middle_name: enteredMiddleName,
				donor_last_name: enteredlastName,
				donor_mobile_number: enteredmobileNumber,
				donor_pan_card_number: enteredPanCard,
				donor_email_id: enteredEmail,
				donor_address1: enteredAddress1,
				donor_address2: enteredAddress2,
				donor_address3: enteredAddress3,
				city: enteredCity,
				state: enteredState,
				pincode: enteredPincode,
				donation_purpose: enteredDonationPurpose,
				donation_type: enteredDonationType,
				payment_mode: enteredPaymentMode,
				bank_name: enteredBankDetails,
				bank_transaction_details: enteredTransactionDetails,
				cheque_number: enteredCheque,
				donation_collected_by: userInfo?.user_id,
				is_donation_approved: 1,
				approved_by: null,
				donation_status_reason: null,
			}
		);

		if (response.data.message === "success") {
			toast.success("User Added Successfully");
			setOpen1(false);
			// setisOtpPage(true);
		}
		setisLoading(false);
	};

	const handleChange = (otp) => {
		setOtp(otp);
	};

	return (
		<div style={{ background: "#e5f2ff", padding: "15px" }} sx={{}} id="app">
			<div>
				{/* <header>
					<div className={"header-headings"}>
						<span>
							{!isOtpPage
								? "Create new account"
								: "Enter your verification code"}
						</span>
					</div>
				</header> */}

				<form className="account-form" onSubmit={signUpHandler}>
					<div className={"account-form-fields"}>
						<Grid container spacing={1}>
							<Grid item lg={3} md={3} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										First Name **
									</InputLabel>
									<input
										id="name"
										name="name"
										type="name"
										placeholder="First Name"
										value={enteredName}
										onChange={nameInputChangeHandler}
										onBlur={nameInputBlurHandler}
									/>
									{nameInputIsInvalid && (
										<p className="error-text">
											Please enter valid Firts Name !
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={3} md={3} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Middle Name (optional)
									</InputLabel>
									<input
										id="middle_name"
										name="middle_name"
										type="text"
										placeholder="Middle Name"
										value={enteredMiddleName}
										onChange={middleNameInputChangeHandler}
										// onBlur={middleNameInputBlurHandler}
									/>
									{middleNameInputIsInvalid && (
										<p className="error-text">
											Please enter valid Middle Name!
										</p>
									)}
								</div>
							</Grid>
							<Grid item lg={3} md={3} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Last Name **
									</InputLabel>
									<input
										id="lastName"
										name="lastName"
										type="lastName"
										placeholder="Last Name"
										value={enteredlastName}
										onChange={lastNameInputChangeHandler}
										onBlur={lastNameInputBlurHandler}
									/>
									{lastNameInputIsInvalid && (
										<p className="error-text">Please enter valid last name!</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Amount **
									</InputLabel>
									<div style={{ display: "flex" }}>
										<div className="rupee-symbol">₹</div>
										<div className={"account-form-fields"}>
											<input
												id="amount"
												name="amount"
												type="amount"
												placeholder="Amount"
												value={enteredAmount}
												onChange={amountInputChangeHandler}
												onBlur={amountInputBlurHandler}
											/>
											{amountInputIsInvalid && (
												<p className="error-text">Please enter valid Amount!</p>
											)}{" "}
										</div>
									</div>
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Pan Card Number **
									</InputLabel>
									<input
										id="PanCard Number"
										name="PanCard Number"
										type="PanCard Number"
										placeholder="Pan Card Number"
										value={enteredPanCard}
										onChange={panCardInputChangeHandler}
										onBlur={panCardInputBlurHandler}
									/>
									{panCardInputIsInvalid && (
										<p className="error-text">Please enter valid Pan Card</p>
									)}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Email Id (optional)
									</InputLabel>
									<input
										id="email"
										name="email"
										type="email"
										placeholder="E-mail"
										value={enteredEmail}
										onChange={emailInputChangeHandler}
										// onBlur={emailInputBlurHandler}
									/>
									{emailInputIsInvalid && (
										<p className="error-text">
											Please enter valid email address!
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Mobile Number **
									</InputLabel>
									<input
										id="mobileNumber"
										name="mobileNumber"
										type="number"
										placeholder="Mobile Number"
										value={enteredmobileNumber}
										onChange={mobileNumberInputChangeHandler}
										onBlur={mobileNumberInputBlurHandler}
									/>
									{mobileNumberInputIsInvalid && (
										<p className="error-text">
											Please enter valid mobile number
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Address1 **
									</InputLabel>
									<input
										id="address1"
										name="address1"
										type="text"
										placeholder="Address 1"
										value={enteredAddress1}
										onChange={address1InputChangeHandler}
										onBlur={address1InputBlurHandler}
									/>
									{address1InputIsInvalid && (
										<p className="error-text">Please enter valid Address</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Address2 **
									</InputLabel>
									<input
										id="address2"
										name="address2"
										type="text"
										placeholder="Address 2"
										value={enteredAddress2}
										onChange={address2InputChangeHandler}
										onBlur={address2InputBlurHandler}
									/>
									{address2InputIsInvalid && (
										<p className="error-text">Please enter valid Address</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Address 3 (optional)
									</InputLabel>
									<input
										id="address3"
										name="address3"
										type="text"
										placeholder="Address 3"
										value={enteredAddress3}
										onChange={address3InputChangeHandler}
										// onBlur={mobileNumberInputBlurHandler}
									/>
									{/* {mobileNumberInputIsInvalid && (
										<p className="error-text">
											Please enter valid mobile number
										</p>
									)}{" "} */}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Pincode **
									</InputLabel>
									<input
										id="pincode"
										name="pincode"
										type="text"
										placeholder="Pincode Number"
										value={enteredPincode}
										onChange={pincodeInputChangeHandler}
										onBlur={pincodeInputBlurHandler}
									/>
									{pincodeInputIsInvalid && (
										<p className="error-text">Please enter valid pincode !</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										City **
									</InputLabel>
									<input
										id="city"
										name="city"
										type="text"
										placeholder="City"
										value={enteredCity}
										onChange={cityInputChangeHandler}
										onBlur={cityInputBlurHandler}
									/>
									{cityInputIsInvalid && (
										<p className="error-text">Please enter valid City !</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										State **
									</InputLabel>
									<input
										id="state"
										name="state"
										type="text"
										placeholder="State"
										value={enteredState}
										onChange={stateInputChangeHandler}
										onBlur={stateInputBlurHandler}
									/>
									{stateInputIsInvalid && (
										<p className="error-text">Please enter valid state !</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Donation Type
									</InputLabel>
									<Select
										id="demo-simple-select-disabled"
										value={enteredDonationType}
										label="DonationType"
										onChange={donationTypeInputChangeHandler}
										defaultValue={1}
										style={{ background: "#fff" }}
									>
										<MenuItem value={1}>Donation</MenuItem>
										<MenuItem value={2}>Rescue Fund</MenuItem>
										<MenuItem value={3}>Cattle Fee</MenuItem>
									</Select>

									{donationTypeInputIsInvalid && (
										<p className="error-text">
											Please enter valid Donation Type
										</p>
									)}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Payment Mode
									</InputLabel>
									<Select
										id="demo-simple-select-disabled"
										value={enteredPaymentMode}
										label="DonationType"
										onChange={paymentModeInputChangeHandler}
										defaultValue={1}
										style={{ background: "#fff" }}
									>
										<MenuItem value={1}>Online</MenuItem>
										<MenuItem value={2}>Cash</MenuItem>
										<MenuItem value={3}>Cheque</MenuItem>
									</Select>

									{donationTypeInputIsInvalid && (
										<p className="error-text">
											Please enter valid Donation Type
										</p>
									)}
								</div>
							</Grid>

							{enteredPaymentMode === 3 && (
								<Grid item lg={6} md={6} sm={12} xs={12}>
									<div className={"account-form-fields"}>
										<InputLabel id="demo-simple-select-helper-label">
											Cheque Number (optional)
										</InputLabel>
										<input
											id="cheque"
											name="cheque"
											type="text"
											placeholder="Cheque"
											value={enteredCheque}
											onChange={chequeInputChangeHandler}
											onBlur={chequeInputBlurHandler}
										/>
										{/* {addressInputIsInvalid && (
										<p className="error-text"> Please enter valid address</p>
									)} */}
									</div>
								</Grid>
							)}
							{enteredPaymentMode === 1 && (
								<>
									{" "}
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<div className={"account-form-fields"}>
											<InputLabel id="demo-simple-select-helper-label">
												Bank Name (optional)
											</InputLabel>
											<input
												id="bankDetails"
												name="bankDetails"
												type="text"
												placeholder="Bank Details"
												value={enteredBankDetails}
												onChange={bankDetailsInputChangeHandler}
												// onBlur={bankDetailsInputBlurHandler}
											/>
											{/* {addressInputIsInvalid && (
								<p className="error-text"> Please enter valid address</p>
							)} */}
										</div>
									</Grid>
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<div className={"account-form-fields"}>
											<InputLabel id="demo-simple-select-helper-label">
												Transaction Details (optional)
											</InputLabel>
											<input
												id="TransactionDetails"
												name="TransactionDetails"
												type="text"
												placeholder="Transaction Details"
												value={enteredTransactionDetails}
												onChange={transactionDetailsInputChangeHandler}
												// onBlur={bankDetailsInputBlurHandler}
											/>
											{/* {addressInputIsInvalid && (
								<p className="error-text"> Please enter valid address</p>
							)} */}
										</div>
									</Grid>
								</>
							)}
							<Grid item lg={12} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Donation Purspose (optional)
									</InputLabel>
									<input
										id="Donation_Purspose"
										name="Donation Purspose"
										type="text"
										placeholder="Donation Purspose"
										value={enteredDonationPurpose}
										onChange={donationPurposeInputChangeHandler}
										onBlur={donationPurposeInputBlurHandler}
									/>
									{/* {addressInputIsInvalid && (
										<p className="error-text"> Please enter valid address</p>
									)} */}
								</div>
							</Grid>

							<Grid item lg={12} md={6} sm={12} xs={12}>
								<div className={"account-form-fields-readonly "}>
									<InputLabel id="demo-simple-select-helper-label">
										Donation Received by **
									</InputLabel>
									<input
										id="address"
										name="address"
										type="text"
										readOnly
										placeholder="address"
										value={`${userInfo?.first_name} ${userInfo?.last_name}`}
										// onChange={addressInputChangeHandler}
										// onBlur={addressInputBlurHandler}
									/>
									{/* {addressInputIsInvalid && (
										<p className="error-text"> Please enter valid address</p>
									)} */}
								</div>
							</Grid>
						</Grid>
					</div>
					<button className="btn-submit-form" type="submit">
						{isloading ? (
							<div className="display-block">
								{" "}
								<Loader wrapperStyle={{ display: "block" }} />
							</div>
						) : (
							"Add Receipt"
						)}
					</button>
				</form>
				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
					<Link to="/login">Already have an account</Link>
				</div> */}
			</div>
		</div>
	);
}

export default AddUser;
