import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Loader = ({
	height = "20",
	width = "20",
	radius = "12",
	color = "#fff",
}) => {
	return (
		<ThreeDots
			height={height}
			width={width}
			radius={radius}
			color={color}
			ariaLabel="three-dots-loading"
			wrapperStyle={{}}
			wrapperClassName=""
			visible={true}
		/>
	);
};

export default Loader;
